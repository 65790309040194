<template>
  <div class="dashboard" v-if="user">
    <b-row>
      <b-col cols="12">
        <filters
            :disabled="loading"
            :show-license-type-filter="true"
            @input="selectFilter"
            @select:filter="selectFilter"
            @apply="apply"
        />
      </b-col>
    </b-row>

    <!--   Course type -->
    <b-row>
      <b-col>
        <main-table
            :ref="'by_course_type'"
            :list="appData.by_course_type"
            :fields="courseTypeFields"
            :loading="loading"
            title="By Course Type"
            @click:update="updateTable('by_course_type')"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <main-table
            :ref="'by_course_type_total'"
            :list="appData.by_course_type_total"
            :fields="courseTypeFields"
            :loading="loading"
            title="By Course Type (total)"
            @click:update="updateTable('by_course_type_total')"
        />
      </b-col>
    </b-row>

    <!--  Exam type | Exam Results  -->
    <b-row>
      <b-col>
        <main-table
            :ref="'by_exam_type'"
            :list="appData.by_exam_type"
            :fields="examTypeFields"
            :loading="loading"
            title="By Exam Type (paid)"
            @click:update="updateTable('by_exam_type')"
        />
      </b-col>

      <b-col>
        <main-table
            :ref="'by_exam_results'"
            :list="appData.by_exam_results"
            :fields="examResultsFields"
            :loading="loading"
            sort-value="status"
            title="By Exam Results"
            @click:update="updateTable('by_exam_results')"
        />
      </b-col>
    </b-row>

    <!--   Extra exams les | Extra exams online -->
    <b-row>
      <b-col>
        <main-table
            :ref="'by_extra_exams_les'"
            :list="appData.by_extra_exams_les"
            :fields="extraExamFields"
            :loading="loading"
            title="Extra exams (LES)"
            @click:update="updateTable('by_extra_exams_les')"
        />
      </b-col>
      <b-col>
        <main-table
            :ref="'by_extra_exams_online'"
            :list="appData.by_extra_exams_online"
            :fields="extraExamFields"
            :loading="loading"
            title="Extra exams (ONLINE)"
            @click:update="updateTable('by_extra_exams_online')"
        />
      </b-col>
    </b-row>

    <!--   Online Packages | Categories -->
    <b-row>
      <b-col>
        <main-table
            :ref="'by_package'"
            :list="appData.by_package"
            :fields="packageFields"
            :loading="loading"
            title="Online packages"
            @click:update="updateTable('by_package')"
        />
      </b-col>
      <b-col>
        <main-table
            :ref="'by_course_option'"
            :list="appData.by_course_option"
            :fields="categoryFields"
            :loading="loading"
            title="Category (paid)"
            @click:update="updateTable('by_course_option')"
        />
      </b-col>
    </b-row>

    <!--   Cities | Locations -->
    <b-row>
      <b-col>
        <main-table
            :ref="'by_city'"
            :list="appData.by_city"
            :fields="cityFields"
            :loading="loading"
            sort-value="status"
            title="By cities (paid)"
            @click:update="updateTable('by_city')"
        />
      </b-col>
      <b-col>
        <main-table
            :ref="'by_location'"
            :list="appData.by_location"
            :fields="locationFields"
            :loading="loading"
            sort-value="status"
            title="By Location (paid)"
            @click:update="updateTable('by_location')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BRow, BCol } from 'bootstrap-vue'
import DashboardModel from '@/models/dashboard-model'
import { UserService } from '@/services/user.service'
import { UsersService } from '@/services/users.service'
import Filters from '@/components/filters/Filters.vue'
import mainTable from '@/components/tables/MainTable.vue'

export default {
  name: 'Dashboard',

  components: {
    BRow,
    BCol,
    Filters,
    mainTable
  },

  setup(props, ctx) {
    const userService = new UserService(ctx.root.$http);
    const usersService = new UsersService(ctx.root.$http);

    return {
      userService,
      usersService,
      courseTypeFields: [
        { key: 'name', label: 'Course Type', sortable: true },
        { key: 'total', sortable: true },
        { key: 'unique', sortable: true },
        { key: 'paid', sortable: true },
        { key: 'ratio', label: 'Paid/Unique', sortable: true },
        { key: 'paid_wn_24h', label: 'Paid w/n 24h', sortable: true }
      ],
      examTypeFields: [
        { key: 'name', sortable: true },
        { key: 'offline', sortable: true },
        { key: 'online', sortable: true },
        { key: 'total', sortable: true }
      ],
      examResultsFields: [
        { key: 'status', sortable: true },
        { key: 'count', sortable: true },
        { key: 'ratio', sortable: true }
      ],
      categoryFields: [
        { key: 'name', sortable: true },
        { key: 'count', sortable: true }
      ],
      cityFields: [
        { key: 'name', sortable: true },
        { key: 'count', sortable: true }
      ],
      locationFields: [
        { key: 'name', sortable: true },
        { key: 'count', sortable: true }
      ],
      packageFields: [
        { key: 'name', sortable: true },
        { key: 'payments_count', label: 'Amount', sortable: true },
        { key: 'money', sortable: true }
      ],
      extraExamFields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'money', label: 'Money', sortable: true },
        { key: 'payments_count', label: 'Payments count', sortable: true }
      ],
    }
  },

  data: () => ({
    loading: false,
    appData: null,
    filters: {
      date_after: '',
      date_before: ''
    }
  }),

  computed: {
    ...mapGetters({
      user: 'user/user'
    }),

    hasDateAfter() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.date_after);
    },

    hasDateBefore() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.date_before);
    }
  },

  created() {
    this.appData = new DashboardModel()
  },

  mounted() {
    // this.preSelectDateFilters()
  },

  methods: {
    async preSelectDateFilters() {
      let _today = this.$moment().format('YYYY-MM-DD')
      let _getCookies = this.$cookies.get('filters')

      if (!this.hasDateAfter || !this.hasDateBefore) {
        await this.selectFilter({
          date_after: _today,
          date_before: _today
        })
      } else {
        await this.selectFilter({ date_after: _getCookies.date_after, date_before: _getCookies.date_before })
      }
      await this.apply();
    },

    async updateTable(key) {
      try {
        this.loading = true
        let res = await this.usersService.getStatistics({ ...this.$route.query, blocks: key })
        this.appData[key] = res[key]
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async fetchData(query) {
      try {
        this.loading = true
        let res = await this.usersService.getStatistics({ ...this.$route.query, ...query })
        this.appData = new DashboardModel({ ...this.appData, ...res })
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async selectFilter(payload) {
      if (this.loading) return
      this.filters = { ...this.filters, ...payload }
      this.$cookies.set('filters', this.filters);
      await this.setQuery(this.filters)
    },

    async apply() {
      await this.fetchData()
    },

    async setQuery(data) {
      const query = { ...this.$route.query, ...data };
      Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null) && delete query[key]);
      await this.$router.push({ query }).catch(() => {});
    },
  }
}
</script>

<style lang="scss">
.dashboard {
  flex-direction: column;
}
</style>
