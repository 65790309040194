export default class DashboardModel {
  constructor(item = {}) {
    this.by_city = item.by_city?.map(item => new CityModel(item)) || []
    this.by_course_option = item.by_course_option?.map(item => new CategoryModel(item)) || []
    this.by_course_type = item.by_course_type?.map(item => new CourseTypeModel(item)) || []
    this.by_course_type_total = item.by_course_type_total?.map(item => new CourseTypeModel(item)) || []
    this.by_exam_results = item.by_exam_results?.map(item => new ExamResultsModel(item)) || []
    this.by_exam_type = item.by_exam_type?.map(item => new ExamTypeModel(item)) || []
    this.by_extra_exams_les = item.by_extra_exams_les?.map(item => new ExtraExamsModel(item)) || []
    this.by_extra_exams_online = item.by_extra_exams_online?.map(item => new ExtraExamsModel(item)) || []
    this.by_location = item.by_location?.map(item => new LocationModel(item)) || []
    this.by_package = item.by_package?.map(item => new PackageModel(item)) || []
  }
}

export class CountModel {
  constructor(item = {}) {
    this.name = item.name
    this.count = item.cnt || 0
  }
}

export class CourseTypeModel {
  constructor(item = {}) {
    this.name = item.name
    this.paid = item.paid || 0
    this.paid_wn_24h = item.paid_wn_24h || 0
    this.ratio = getRatio(item.ratio)
    this.total = item.total || 0
    this.unique = item.unique || 0
  }
}

export class CityModel extends CountModel {}

export class LocationModel extends CountModel {}

export class CategoryModel extends CountModel {}

export class PackageModel {
  constructor(item = {}) {
    this.name = item.name
    this.payments_count = item.payments_count || 0
    this.money = item.money || 0
  }
}

export class ExtraExamsModel {
  constructor(item = {}) {
    this.name = item.name
    this.money = item.money || 0
    this.payments_count = item.payments_count || 0
  }
}

export class ExamTypeModel {
  constructor(item = {}) {
    this.name = item.name
    this.offline = item.offline || 0
    this.online = item.online || 0
    this.total = item.total || 0
  }
}

export class ExamResultsModel {
  constructor(item = {}) {
    this.status = item.status
    this.count = item.count || 0
    this.ratio = getRatio(item.ratio)
  }
}

function getRatio(item) {
  if (item) return `${ item.toFixed(2) } %`
  else return '0 %'
}

