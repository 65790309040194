import {ApiServiceAbstract} from "@/services/api.service.abstract"

const qs = require('qs')

export class UsersService extends ApiServiceAbstract {
  constructor(http) {
    super(http);
  }

  getStatistics = (query) => {
    return this.request(this.http.get, 'statistic_dashboard/json/', {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    });
  }
}
